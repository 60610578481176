// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

//= require popper
//= require bootstrap


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function(){
   var flashContainerId = 'alert';

   function removeFlashMessages() {
     $('#' + flashContainerId).remove();
   }

   setTimeout(removeFlashMessages, 3000);
})

import "../styles/competition_creator.scss"
import "../styles/competition_dashboard.scss"
import "../styles/competition_finder.scss"
import "../styles/competition_list.scss"
import "../styles/competition_type.scss"
import "../styles/competition.scss"
import "../styles/dashboard.scss"
import "../styles/gameday_player.scss"
import "../styles/home.scss"
import "../styles/player_avatar_colors.scss"
import "../styles/player_list.scss"
import "../styles/player_modal.scss"
import "../styles/player_picked.scss"
import "../styles/player_toast.scss"
import "../styles/roles_buttons.scss"
import "../styles/scroll_to_top.scss"
import "../styles/sport_competitions.scss"
import "../styles/table.scss"
import "../styles/team_colours.scss"
import "../styles/toast.scss"
